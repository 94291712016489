import request from '@/utils/request'

// 获取反馈列表，如果是会员获取，只返回当前会员的数据
export function GetFeedbackList(data) {
    return request({
        url: '/api/services/app/Feedback/GetPagedList',
        method: 'get',
        params: data
    })
}

// 获取反馈详细信息
export function GetFeedbackInfo(data) {
    return request({
        url: '/api/services/app/Feedback/GetInfo',
        method: 'get',
        params: data
    })
}

// 获取型号列表
export function GetFeedbackHandle(data) {
    return request({
        url: '/api/services/app/Feedback/Handle',
        method: 'post',
        data
    })
}