<style lang="scss" scoped>
/deep/ .comp-handle-order {
    max-width: 520px;
} 
</style>

<template>
    <div>
        <el-dialog title="处理订单" custom-class="c-el-dialog comp-handle-order" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="是否处理" prop="isHandled">
                            <el-radio v-model="dialogData.isHandled" :label="true">是</el-radio>
                            <el-radio v-model="dialogData.isHandled" :label="false">否</el-radio>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="备注" prop="remark">
                            <el-input type="textarea" rows="5" v-model="dialogData.remark" placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="图片">
                            <multiple-images :imageUrls="dialogData.replyImages" :folder="'member'" :multiple="true" @emitImageUrls="setImageUrls(arguments)"></multiple-images>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import MultipleImages from '@/components/ImageMultipleUpload'
import * as funFeedback from "@/api/feedback/index"
export default {
    name: "compHandleOrder",
    components: { MultipleImages },
    data() {
        return {
            showDialog: false,
            dialogType: "",
            defaultData: {
                id: "",
                remark: "",
                isHandled: true,
                replyImages: []
            },
            dialogData: {},
            formRefName: "refAgentEditForm",
            formRules: {
                remark: [{ required: true, message: "请输入备注", trigger: "blur" }]
            },
        }
    },
    methods: {
        show(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultData))
            this.dialogData.id = row.id
            this.showDialog = true
        },
        // 多图上传成功
        setImageUrls(val) {
            if (val[2]) {
                this.dialogData.replyImages = JSON.parse(JSON.stringify(val[2]))
                return false
            }
            if (val[0] >= 0) {
                if (!val[1]) {
                    this.dialogData.replyImages.splice(val[0], 1)
                } else {
                    this.dialogData.replyImages.splice(val[0], 1, val[1])
                }
            } else {
                this.dialogData.replyImages.push(val[1])
            }
        },
        _save() {
            this.$refs[this.formRefName].validate((valid) => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    window.$common.fullLoading()
                    funFeedback.GetFeedbackHandle(commitData).then(() => {
                        this.commonSuccess()
                    })
                } else {
                    console.log('error submit!')
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.successMsg(tips, {
                onClose: () => {
                    this._close()
                    window.$common.closeFullLoading()
                    this.refreshData()
                }
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>