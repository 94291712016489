<style lang="scss" scoped>
.page-member-questionback {

}
</style>

<template>
    <div class="page-member-questionback">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl"></div>
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']"
                        @change="_search" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" size="medium" align="right">
                    </el-date-picker>
                    <el-select class="mrgr5 mrgb5" v-model="filter.status" placeholder="处理状态" clearable @change="_search()" size="medium">
                        <el-option v-for="item in statusOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                    <el-select class="mrgr5 mrgb5" v-model="filter.type" placeholder="问题类型" clearable @change="_search()" size="medium">
                        <el-option v-for="item in questionStatus" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="订单号/设备号" clearable v-model="filter.keywords" size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id">
                <el-table-column prop="id" label="ID" width="60"></el-table-column>
                <el-table-column prop="sourceId" label="订单号/设备号" width="200">
                    <template slot-scope="scope">
                    <a href="javascript:" @click="skipToRoute(`/order/details?id=${scope.row.sourceId}`)"
                        style="text-decoration: underline;" v-if="scope.row.type == 2">{{ scope.row.sourceId }}</a>
                    <a href="javascript:" @click="skipToRoute(`/device/details?id=${scope.row.sourceId}`)"
                        style="text-decoration:underline" v-if="scope.row.type == 1">{{ scope.row.sourceId }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="creatorUserId" label="会员ID">
                    <template slot-scope="scope">
                        <a href="javascript:"  @click="skipToRoute(`/member/list?id=${scope.row.creatorUserId}`)"  style="text-decoration:underline;">{{ scope.row.creatorUserId }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="contactTel" label="问题类型">
                    <template slot-scope="scope">
                        <el-tag class="mrgr5" type="warning" size="info" v-if="scope.row.type == '1'">设备故障</el-tag>
                        <el-tag class="mrgr5" type="danger" size="mini" v-if="scope.row.type == '2'">订单问题</el-tag>
                        <el-tag class="mrgr5" size="mini" v-if="scope.row.type == '3'">其他</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="contactTel" label="联系电话"></el-table-column>
                <el-table-column prop="content" label="消息"></el-table-column>
                <el-table-column prop="statusDesc" label="状态(处理员ID)">
                    <template slot-scope="scope">
                        {{scope.row.statusDesc}}
                        <template v-if="scope.row.status == 1">({{scope.row.handledAdminId}})</template>
                    </template>
                </el-table-column>
                <el-table-column label="图片" header-align="center">
                    <template slot-scope="scope">
                        <a target="_blank" :href="item" v-for="item in scope.row.images" :key="item">
                            <img :src="item" width="60px" height="60px" alt />
                        </a>
                    </template>
                </el-table-column>
                <el-table-column label="处理方式">
                    <template slot-scope="scope" v-if="scope.row.remark">{{scope.row.remark}}</template>
                </el-table-column>
                <el-table-column prop="creationTime" label="提交时间" width="160"></el-table-column>
                <el-table-column prop="handledTime" label="处理时间" width="160"></el-table-column>

                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="openHandleDialog(scope.row)" v-if="scope.row.status == 0">处理</el-button>
                        <el-button size="mini" type="success" @click="openHandleDialog(scope.row)" v-if="scope.row.status == 1" disabled>已处理</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--处理订单-->
        <handle-order :ref="refHandleOrder" @refreshData="_search()"></handle-order>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funFeedback from "@/api/feedback/index"
import HandleOrder from "./components/handleOrder"
import moment from "moment"
export default {
    name: "pageMemberQuestionBack",
    mixins: [enumConfigs],
    components: { HandleOrder },
    data() {
        const that = this
        return {
            refHandleOrder: "refQuestionBackToHandleOrder",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                keywords: "",
                onlyShowAgent: false,
                clientType: null,
                sortDirection: 0,
                sortField: null,
                startDate: null,
                endDate: null
            },
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    computed: {
        statusOptions() {
            return this.arrFeedbackStatus
        },
        questionStatus() {
            return [
                { value: "1", name: "设备故障" },
                { value: "2", name: "订单问题" },
                { value: "3", name: "其他" }
            ]
        }
    },
    activated() {
        this.getDataList()
    },
    created() {
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.filter.startDate = timeArr[0] || ""
            this.filter.endDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.filter.startDate = psTime
            this.filter.endDate = peTime
            this.selectedTime = [this.filter.startDate, this.filter.endDate]
        },
        async getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            funFeedback.GetFeedbackList(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        openHandleDialog(row) {
            this.$refs[this.refHandleOrder].show(row)
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        },
    }
}
</script>